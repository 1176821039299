import * as React from 'react';
import { Link } from 'gatsby';

import SecondaryPageLayout from '../components/secondary-page-layout';
import Meta from '../components/meta';
import { NotFoundPageTitle } from '../localisation';

function NotFoundPage() {
  return (
    <SecondaryPageLayout>
      <Meta
        title={NotFoundPageTitle}
      />

      <h2>Page not found</h2>
      <p>
        Sorry, we couldn’t find what you were looking for.
        <br />
        <Link to="/">Go home</Link>
      </p>
    </SecondaryPageLayout>
  );
}

export default NotFoundPage;
